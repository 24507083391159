<template>
    <main class="main-page"  id="">
        <template v-if="pageReady">
            <template v-if="showHeader">
                <section class="page-section mb-3" >
                    <div class="container">
                        <div class="grid align-items-center">
                            <div  v-if="!isSubPage"  class="col-fixed " >
                                <Button @click="$router.go(-1)" label=""  class="p-button p-button-text " icon="pi pi-arrow-left"  />
                            </div>
                            <div  class="col " >
                                <div class=" text-2xl text-primary font-bold" >
                                    Agregar nuevo
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            <section class="page-section " >
                <div class="container">
                    <div class="grid ">
                        <div  class="md:col-9 sm:col-12 comp-grid" >
                            <div >
                                <form ref="observer" tag="form" @submit.prevent="submitForm()" :class="{ 'card ': !isSubPage }" class=" ">
                                    <div class="grid">
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Created Date 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <Calendar  :showButtonBar="true" :showTime="true" dateFormat="yy-mm-dd" hourFormat="24" v-model="formData.created_date" :showIcon="true" class="w-full" :class="getErrorClass('created_date')"     mask="yy-MM-DD HH:mm"     />
                                                    <small v-if="isFieldValid('created_date')" class="p-error">{{ getFieldError('created_date') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Filename 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <div class="mb-3">
                                                        <Uploader :class="getErrorClass('filename')" upload-path="fileuploader/upload/filename" v-model="formData.filename" :fileLimit="1" :maxFileSize="3000000" accept=".jpg,.png,.gif,.jpeg" :multiple="false" label="Elija archivos o suelte archivos aquí" />
                                                    </div>
                                                    <small v-if="isFieldValid('filename')" class="p-error">{{ getFieldError('filename') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    File Size 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <div class="mb-3">
                                                        <Uploader :class="getErrorClass('file_size')" upload-path="fileuploader/upload/file_size" v-model="formData.file_size" :fileLimit="1" :maxFileSize="3000000" accept=".jpg,.png,.gif,.jpeg" :multiple="false" label="Elija archivos o suelte archivos aquí" />
                                                    </div>
                                                    <small v-if="isFieldValid('file_size')" class="p-error">{{ getFieldError('file_size') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Value 01 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlvalue_01" v-model.trim="formData.value_01"  label="Value 01" type="text" placeholder="Escribir Value 01"      
                                                    class=" w-full" :class="getErrorClass('value_01')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('value_01')" class="p-error">{{ getFieldError('value_01') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Value 02 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlvalue_02" v-model.trim="formData.value_02"  label="Value 02" type="text" placeholder="Escribir Value 02"      
                                                    class=" w-full" :class="getErrorClass('value_02')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('value_02')" class="p-error">{{ getFieldError('value_02') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Value 03 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlvalue_03" v-model.trim="formData.value_03"  label="Value 03" type="text" placeholder="Escribir Value 03"      
                                                    class=" w-full" :class="getErrorClass('value_03')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('value_03')" class="p-error">{{ getFieldError('value_03') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Value 04 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlvalue_04" v-model.trim="formData.value_04"  label="Value 04" type="text" placeholder="Escribir Value 04"      
                                                    class=" w-full" :class="getErrorClass('value_04')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('value_04')" class="p-error">{{ getFieldError('value_04') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Value 05 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlvalue_05" v-model.trim="formData.value_05"  label="Value 05" type="text" placeholder="Escribir Value 05"      
                                                    class=" w-full" :class="getErrorClass('value_05')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('value_05')" class="p-error">{{ getFieldError('value_05') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Value 06 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlvalue_06" v-model.trim="formData.value_06"  label="Value 06" type="text" placeholder="Escribir Value 06"      
                                                    class=" w-full" :class="getErrorClass('value_06')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('value_06')" class="p-error">{{ getFieldError('value_06') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Value 07 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlvalue_07" v-model.trim="formData.value_07"  label="Value 07" type="text" placeholder="Escribir Value 07"      
                                                    class=" w-full" :class="getErrorClass('value_07')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('value_07')" class="p-error">{{ getFieldError('value_07') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Value 08 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlvalue_08" v-model.trim="formData.value_08"  label="Value 08" type="text" placeholder="Escribir Value 08"      
                                                    class=" w-full" :class="getErrorClass('value_08')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('value_08')" class="p-error">{{ getFieldError('value_08') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Value 09 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlvalue_09" v-model.trim="formData.value_09"  label="Value 09" type="text" placeholder="Escribir Value 09"      
                                                    class=" w-full" :class="getErrorClass('value_09')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('value_09')" class="p-error">{{ getFieldError('value_09') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Value 10 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlvalue_10" v-model.trim="formData.value_10"  label="Value 10" type="text" placeholder="Escribir Value 10"      
                                                    class=" w-full" :class="getErrorClass('value_10')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('value_10')" class="p-error">{{ getFieldError('value_10') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Value 11 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlvalue_11" v-model.trim="formData.value_11"  label="Value 11" type="text" placeholder="Escribir Value 11"      
                                                    class=" w-full" :class="getErrorClass('value_11')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('value_11')" class="p-error">{{ getFieldError('value_11') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="formgrid grid">
                                                <div class="col-12 md:col-3">
                                                    Value 12 
                                                </div>
                                                <div class="col-12 md:col-9">
                                                    <InputText  ref="ctrlvalue_12" v-model.trim="formData.value_12"  label="Value 12" type="text" placeholder="Escribir Value 12"      
                                                    class=" w-full" :class="getErrorClass('value_12')">
                                                    </InputText>
                                                    <small v-if="isFieldValid('value_12')" class="p-error">{{ getFieldError('value_12') }}</small> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="showSubmitButton" class="text-center my-3">
                                        <Button class="p-button-primary" type="submit" label="Entregar" icon="pi pi-send" :loading="saving" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
    </main>
</template>
<script setup>
	import {  computed,  reactive, toRefs, onMounted } from 'vue';
	import { required } from 'src/services/validators';
	import { useApp } from 'src/composables/app.js';
	import { useAddPage } from 'src/composables/addpage.js';
	import { usePageStore } from 'src/store/page';
	const props = defineProps({
		pageStoreKey: {
			type: String,
			default: 'DATA',
		},
		pageName : {
			type : String,
			default : 'data',
		},
		routeName : {
			type : String,
			default : 'dataadd',
		},
		apiPath : {
			type : String,
			default : 'data/add',
		},
		submitButtonLabel: {
			type: String,
			default: "Entregar",
		},
		formValidationError: {
			type: String,
			default: "El formulario no es válido",
		},
		formValidationMsg: {
			type: String,
			default: "Por favor complete el formulario",
		},
		msgTitle: {
			type: String,
			default: "Crear registro",
		},
		msgAfterSave: {
			type: String,
			default: "Grabar agregado exitosamente",
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type : Boolean,
			default : true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
		pageData: { // use to set formData default values from another page
			type: Object,
			default: () => {} 
		},
	});
	//lines
	const store = usePageStore(props.pageStoreKey);
	const app = useApp();
	
	const formDefaultValues = {
		created_date: "NULL", 
		filename: "", 
		file_size: "", 
		value_01: "NULL", 
		value_02: "NULL", 
		value_03: "NULL", 
		value_04: "NULL", 
		value_05: "NULL", 
		value_06: "NULL", 
		value_07: "NULL", 
		value_08: "NULL", 
		value_09: "NULL", 
		value_10: "NULL", 
		value_11: "NULL", 
		value_12: "NULL", 
	};
	
	const formData = reactive({ ...formDefaultValues });
	
	//vuelidate form validation rules
	const rules = computed(() => {
		return {
			created_date: {  },
			filename: {  },
			file_size: {  },
			value_01: {  },
			value_02: {  },
			value_03: {  },
			value_04: {  },
			value_05: {  },
			value_06: {  },
			value_07: {  },
			value_08: {  },
			value_09: {  },
			value_10: {  },
			value_11: {  },
			value_12: {  }
		}
	});
	
	const page = useAddPage({ store, props, formData, rules, beforeSubmit, afterSubmit });
	
	// event raised before submit form
	function beforeSubmit(){
		return true;
	}
	
	// event raised after form submited
	function afterSubmit(response) { 
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		page.setFormDefaultValues(); //reset form data
		if(app.isDialogOpen()){
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if(props.redirect) {
			app.navigateTo(`/data`);
		}
	}
	
	const {  saving, pageReady, } = toRefs(page.state);
	
	const { submitForm, getErrorClass, getFieldError, isFieldValid,  } = page.methods;
	
	onMounted(()=>{
		const pageTitle = "Agregar nuevo";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
	});
	
	// expose page object for other components access
	defineExpose({
		page
	});
</script>
<style scoped>
</style>
